import { AppLoggedOutMinShell } from "@/components/gen-app-shell";
import { SecurityLink } from "@/components/gen-misc";
import { AppH1 } from "@/components/gen-typography";
import { Button } from "@/components/ui/button";
import { AFTER_SIGN_IN_URL, SIGN_IN_URL, SIGN_UP_URL } from "@/urls";
import { SignUp } from "@clerk/remix";
import { getAuth } from "@clerk/remix/ssr.server";
import { LoaderFunctionArgs, redirect } from "@remix-run/cloudflare";

export const loader = async (args: LoaderFunctionArgs) => {
  const { userId } = await getAuth(args);
  if (userId) redirect("/");
  return {};
};

const SignUpComponent = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8 p-4">
      <AppH1>Welcome to reconfigured</AppH1>
      <SignUp
        forceRedirectUrl={AFTER_SIGN_IN_URL}
        path={SIGN_UP_URL}
        routing="path"
        signInUrl={SIGN_IN_URL}
      />
      <div className="flex flex-col gap-2">
        <Button asChild variant="link">
          <SecurityLink />
        </Button>
      </div>
    </div>
  );
};

const SignInPage = () => {
  return (
    <AppLoggedOutMinShell>
      <SignUpComponent />
    </AppLoggedOutMinShell>
  );
};

export default SignInPage;
